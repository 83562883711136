import React, { FunctionComponent, useState } from "react";
import { DateTimeInput, TimePrecision } from "@plex/react-components";
import { DateTimeFormatStyle } from "@plex/culture-core";
import { INodeConfigPropertiesProps } from "../NodeTypes/PropertyTypeDefinitions";

export interface IDateTimeInputProps extends INodeConfigPropertiesProps {
  label: string;
  formatOptions: DateTimeFormatStyle;
  precision: TimePrecision;
}

export const DateTimeFormField: FunctionComponent<IDateTimeInputProps> = (props) => {
  const [value, setValue] = useState(null);

  const handleValueChange = (newTemporalValue: any) => {
    setValue(newTemporalValue);
    props.node!.data.nodeProperties[props.name] = new Date(newTemporalValue).toISOString().slice(0, 19);
    props.forceNodeUpdate();
  };

  return (
    <DateTimeInput
      key={`${props.sectionName} ${props.label} Input`}
      disabled={false}
      value={value}
      onValueChange={handleValueChange}
      use24HourTime={false}
      formatOptions={props.formatOptions}
      precision={props.precision}
    />
  );
};
