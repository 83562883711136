import { Node, ReactFlowInstance, UpdateNodeInternals } from "reactflow";
import { IReplay, ITestProperties } from "../FlowDocument/TestPropertiesForm";
import { forceNodeUpdate } from "../Util/NodeUtil";
import { IBannerContext, BannerStatus } from "@plex/react-components";
import { FunctionSubscriberContextProps } from "../FunctionSubscriberContext/FunctionSubscriberProvider";
import { setNodeSelection } from "../Util/NodeUtil";
import { DataType } from "../NodeTypes/TypeDefinitions";

export interface INodeTrace {
  id: string;
  entryIndex: number;
  hasSnapshotInput: boolean;
  hasSnapshotOutput: boolean;
  jsonSnapshot: IJsonSnapshot;
}

export interface IExecutionSummary {
  executionId: string;
  success: boolean;
  errors: IExecutionError[];
  executionTime: number;
  nodeTraceCount: number;
}

export interface IExecutionError {
  code?: string;
  message: string;
  nodeId?: string;
}

export interface IJsonSnapshot {
  entryIndex: number;
  inputs: string;
  outputs: string;
}

export const resetReplayState = () => {
  const initialReplayState = {
    nodeTraces: [],
    isPaused: false,
    isStopped: true,
    isStopping: false,
    traceIndex: 0
  };

  setTestPropertiesState({
    runtimeNodeDelayMs: 200,
    isValid: true,
    errors: [],
    executionTime: 0,
    nodeTraceCount: 0,
    replay: initialReplayState
  });

  globalThis.testPropertiesState = {
    ...globalThis.testPropertiesState,
    replay: initialReplayState
  };
};

export const playReplay = (
  reactFlowInstance: ReactFlowInstance,
  updateNodeInternals: UpdateNodeInternals,
  bannerContext: IBannerContext,
  functionSubscriber: Partial<FunctionSubscriberContextProps>
): Promise<void> | undefined => {
  const testState: ITestProperties = getTestState();
  let replayState: IReplay = testState.replay;

  if (replayState.traceIndex > testState.nodeTraceCount - 1) {
    exitReplay();
    return Promise.resolve();
  }

  loadTraceEntriesData(replayState.traceIndex, reactFlowInstance, updateNodeInternals, functionSubscriber).then(() => {
    const nodeTrace: INodeTrace = replayState.nodeTraces[replayState.traceIndex]!;
    const node = reactFlowInstance.getNode(nodeTrace.id);

    if (node) {
      if (replayState.isStopped) {
        reactFlowInstance.getNodes().forEach((n: Node<any>) => {
          deleteReplayNodeProperties(n, true);
        });
        setReplayStopped(false);
      }

      return Promise.resolve()
        .then(() => {
          node.data.nodeProperties.isCurrentTrace = true;
          setNodeEvaluating(node, true, updateNodeInternals);
        })
        .then(() => timeout(getTestState().runtimeNodeDelayMs))
        .then(() =>
          continueReplay(node, nodeTrace, reactFlowInstance, updateNodeInternals, bannerContext, functionSubscriber)
        );
    } else {
      exitReplay();
      bannerContext.addMessage("Cannot continue replay, node is missing.", BannerStatus.warning);
      return Promise.resolve();
    }
  });
};

/** Check to see if a nodeTrace exists for the index and if not, grab the data from the api. */
const loadTraceEntriesData = (
  traceIndex: number,
  reactFlowInstance: ReactFlowInstance,
  updateNodeInternals: UpdateNodeInternals,
  functionSubscriber: Partial<FunctionSubscriberContextProps>
): Promise<void> => {
  return new Promise((resolve) => {
    let replayState = getReplayState();
    if (!replayState.nodeTraces[traceIndex]) {
      setTraceEntries(replayState.executionId!, traceIndex, functionSubscriber).then(() => {
        // Grab snapshot data if we have a selected node
        // The last snapshot batch may have been truncated because we didn't have the next set of entries
        // This will prevent skipping any snapshots
        loadNodeTraceSnapshotDataForSelectedNode(
          traceIndex,
          reactFlowInstance,
          updateNodeInternals,
          functionSubscriber
        );
        resolve();
      });
    } else {
      resolve();
    }
  });
};

const exitReplay = () => {
  setReplayTraceIndex(0);
  setReplayPaused(true);
  setReplayStopped(true);
  setReplayStopping(false);
};

const continueReplay = (
  node: Node<any>,
  nodeTrace: INodeTrace,
  reactFlowInstance: ReactFlowInstance,
  updateNodeInternals: UpdateNodeInternals,
  bannerContext: IBannerContext,
  functionSubscriber: Partial<FunctionSubscriberContextProps>
) => {
  {
    const testState = getTestState();
    const replayState = testState.replay;

    loadNodeTraceSnapshotDataForSelectedNode(
      replayState.traceIndex + 1,
      reactFlowInstance,
      updateNodeInternals,
      functionSubscriber
    );

    setNodeEvaluating(node, false, updateNodeInternals);

    if (!replayState.isPaused) {
      if (!replayState.isStopping && replayState.breakPointNodeId && nodeTrace.id === replayState.breakPointNodeId) {
        setReplayPaused(true);
        setNodeSelection([node.id], reactFlowInstance);
      } else {
        if (replayState.traceIndex < testState.nodeTraceCount - 1) {
          node.data.nodeProperties.isCurrentTrace = false;
          setReplayTraceIndex(replayState.traceIndex + 1);
          playReplay(reactFlowInstance, updateNodeInternals, bannerContext, functionSubscriber);
        } else {
          // We've hit the last node and can pause here
          setReplayPaused(true);
        }
      }
    }
  }
};

const setNodeEvaluating = (node: Node<any>, evaluating: boolean, updateNodeInternals: UpdateNodeInternals) => {
  node.data.nodeProperties.isEvaluating = evaluating;
  node.position.x += evaluating ? 0.0001 : -0.0001;
  updateNodeInternals(node.id);
};

export const stopReplay = (reactFlowInstance: ReactFlowInstance, callback?: () => void) => {
  const replayState = getReplayState();

  if (replayState.isStopped) {
    return;
  }

  reactFlowInstance.getNodes().forEach((n: Node<any>) => deleteReplayNodeProperties(n, true));

  exitReplay();

  if (callback) {
    callback();
  }
};

export const setReplayStopped = (isStopped: boolean) => {
  const replayState: IReplay = getReplayState();
  const newReplayState: IReplay = { ...replayState, isStopped: isStopped };
  const testPropertiesState: ITestProperties = {
    ...globalThis.testPropertiesState,
    replay: newReplayState
  };
  globalThis.testPropertiesState = testPropertiesState;
  globalThis.setTestPropertiesState(testPropertiesState);
};

export const setReplayStopping = (isStopping: boolean) => {
  const replayState: IReplay = getReplayState();
  const newReplayState: IReplay = { ...replayState, isStopping: isStopping };
  const testPropertiesState: ITestProperties = {
    ...globalThis.testPropertiesState,
    replay: newReplayState
  };
  globalThis.testPropertiesState = testPropertiesState;
  globalThis.setTestPropertiesState(testPropertiesState);
};

export const stepForward = (
  reactFlowInstance: ReactFlowInstance,
  updateNodeInternals: UpdateNodeInternals,
  bannerContext: IBannerContext,
  functionSubscriber: Partial<FunctionSubscriberContextProps>
): Promise<void> => {
  const testState = getTestState();
  const replayState = testState.replay;

  if (replayState.traceIndex > testState.nodeTraceCount - 2 || !replayState.isPaused) {
    return Promise.resolve();
  }

  const oldTraceIndex = replayState.traceIndex;
  const newTraceIndex = oldTraceIndex + 1;

  return new Promise<void>((resolve) => {
    loadTraceEntriesData(newTraceIndex, reactFlowInstance, updateNodeInternals, functionSubscriber).then(() => {
      const oldTraceNode = getNodesByTraceIndex(oldTraceIndex, reactFlowInstance);
      const newTraceNode = getNodesByTraceIndex(newTraceIndex, reactFlowInstance);
      if (oldTraceNode) {
        oldTraceNode.data.nodeProperties.isCurrentTrace = false;
      }
      if (newTraceNode) {
        newTraceNode.data.nodeProperties.isCurrentTrace = true;
      } else {
        bannerContext.addMessage("Cannot step forward, node is missing.", BannerStatus.warning);
      }

      loadNodeTraceSnapshotDataForSelectedNode(
        newTraceIndex,
        reactFlowInstance,
        updateNodeInternals,
        functionSubscriber
      );

      setReplayTraceIndex(newTraceIndex);
      resolve();
    });
  });
};

export const stepBack = (
  reactFlowInstance: ReactFlowInstance,
  updateNodeInternals: UpdateNodeInternals,
  bannerContext: IBannerContext,
  functionSubscriber: Partial<FunctionSubscriberContextProps>
) => {
  const replayState: IReplay = getReplayState();

  if (!replayState.isPaused || replayState.traceIndex === 0) {
    return;
  }

  const oldTraceIndex = replayState.traceIndex;
  const newTraceIndex = oldTraceIndex - 1;

  loadTraceEntriesData(newTraceIndex, reactFlowInstance, updateNodeInternals, functionSubscriber).then(() => {
    const oldTraceNode = getNodesByTraceIndex(oldTraceIndex, reactFlowInstance);
    const newTraceNode = getNodesByTraceIndex(newTraceIndex, reactFlowInstance);
    if (oldTraceNode) {
      deleteReplayNodeProperties(oldTraceNode!);
      oldTraceNode.data.nodeProperties.isCurrentTrace = false;
    }
    if (newTraceNode) {
      newTraceNode.data.nodeProperties.isCurrentTrace = true;
    } else {
      bannerContext.addMessage("Cannot step backward, node is missing.", BannerStatus.warning);
    }

    loadNodeTraceSnapshotDataForSelectedNode(newTraceIndex, reactFlowInstance, updateNodeInternals, functionSubscriber);

    setReplayTraceIndex(newTraceIndex);
  });
};

export const skipToFirst = (
  reactFlowInstance: ReactFlowInstance,
  updateNodeInternals: UpdateNodeInternals,
  bannerContext: IBannerContext
) => {
  const replayState: IReplay = getReplayState();

  // reset the currentTrace flag and snapshot data for all nodes
  reactFlowInstance.getNodes().forEach((n: Node<any>) => deleteReplayNodeProperties(n));

  // only set the first node's isCurrentTrace if paused
  //   Unpaused will immediately move to the next node without clearing this
  if (replayState.isPaused) {
    const firstNode = getNodesByTraceIndex(0, reactFlowInstance);

    if (firstNode) {
      firstNode.data.nodeProperties.isCurrentTrace = true;
      updateNodeInternals(firstNode!.id);
    } else {
      bannerContext.addMessage("Cannot skip to the first node, node is missing.", BannerStatus.warning);
    }
  }

  setReplayTraceIndex(0);
};

export const skipToLast = (
  reactFlowInstance: ReactFlowInstance,
  updateNodeInternals: UpdateNodeInternals,
  bannerContext: IBannerContext,
  functionSubscriber: Partial<FunctionSubscriberContextProps>
) => {
  const testState: ITestProperties = getTestState();
  let replayState: IReplay = getReplayState();

  const lastTraceIndex = testState.nodeTraceCount - 1;
  if (replayState.traceIndex === lastTraceIndex) {
    return;
  }

  const oldTraceIndex = replayState.traceIndex;
  const newTraceIndex = lastTraceIndex;

  loadTraceEntriesData(newTraceIndex, reactFlowInstance, updateNodeInternals, functionSubscriber).then(() => {
    const oldTraceNode = getNodesByTraceIndex(oldTraceIndex, reactFlowInstance);
    const newTraceNode = getNodesByTraceIndex(newTraceIndex, reactFlowInstance);
    if (oldTraceNode) {
      deleteReplayNodeProperties(oldTraceNode!);
      oldTraceNode.data.nodeProperties.isCurrentTrace = false;
    }
    if (newTraceNode) {
      newTraceNode.data.nodeProperties.isCurrentTrace = true;
    } else {
      bannerContext.addMessage("Cannot step to the last node, node is missing.", BannerStatus.warning);
    }

    loadNodeTraceSnapshotDataForSelectedNode(newTraceIndex, reactFlowInstance, updateNodeInternals, functionSubscriber);

    setReplayTraceIndex(newTraceIndex);
  });
};

const getNodesByTraceIndex = (traceIndex: number, reactFlowInstance: ReactFlowInstance): Node<any> | undefined => {
  const trace: INodeTrace | undefined = getReplayState().nodeTraces[traceIndex];
  return trace ? reactFlowInstance.getNode(trace.id) : undefined;
};

export const setNodeDelayMs = (nodeDelayMs: number) => {
  const testPropertiesState: ITestProperties = {
    ...globalThis.testPropertiesState,
    runtimeNodeDelayMs: nodeDelayMs
  };
  globalThis.testPropertiesState = testPropertiesState;
  globalThis.setTestPropertiesState(testPropertiesState);
};

export const setReplayPaused = (isPaused: boolean) => {
  const replayState: IReplay = getReplayState();
  const newReplayState: IReplay = { ...replayState, isPaused: isPaused };
  const testPropertiesState: ITestProperties = {
    ...globalThis.testPropertiesState,
    replay: newReplayState
  };
  globalThis.testPropertiesState = testPropertiesState;
  globalThis.setTestPropertiesState(testPropertiesState);
};

export const updateReplayState = (replayState: IReplay, traceIndex: number) => {
  const newReplayState: IReplay = { ...replayState, traceIndex: traceIndex };
  const testPropertiesState: ITestProperties = {
    ...globalThis.testPropertiesState,
    replay: newReplayState
  };
  globalThis.testPropertiesState = testPropertiesState;
  globalThis.setTestPropertiesState(testPropertiesState);
};

export const setReplayTraceIndex = (traceIndex: number) => {
  let replayState: IReplay = getReplayState();
  const newReplayState: IReplay = { ...replayState, traceIndex: traceIndex };
  const testPropertiesState: ITestProperties = {
    ...globalThis.testPropertiesState,
    replay: newReplayState
  };
  globalThis.testPropertiesState = testPropertiesState;
  globalThis.setTestPropertiesState(testPropertiesState);
};

export const updateBreakpointNodeId = (
  reactFlowInstance: ReactFlowInstance,
  updateNodeInternals: UpdateNodeInternals
) => {
  const selectedNode = reactFlowInstance.getNodes().find((n: Node<any>) => n.selected);
  let selectedNodeId = selectedNode?.id;
  let replayState: IReplay = getReplayState();

  if (selectedNode) {
    selectedNode.data.nodeProperties.isBreakpoint = true;
    forceNodeUpdate(selectedNode, updateNodeInternals, reactFlowInstance);
  }

  if (replayState.breakPointNodeId) {
    const oldSelectedNode = reactFlowInstance.getNode(replayState.breakPointNodeId);
    if (oldSelectedNode) {
      delete oldSelectedNode.data.nodeProperties.isBreakpoint;
      forceNodeUpdate(oldSelectedNode, updateNodeInternals, reactFlowInstance);
    }

    if (replayState.breakPointNodeId === selectedNodeId) {
      selectedNodeId = undefined;
    }
  }

  const newReplayState: IReplay = { ...replayState, breakPointNodeId: selectedNodeId };
  const testPropertiesState: ITestProperties = {
    ...globalThis.testPropertiesState,
    replay: newReplayState
  };
  globalThis.testPropertiesState = testPropertiesState;
  globalThis.setTestPropertiesState(testPropertiesState);
};

export const getReplayState = (): IReplay => globalThis.testPropertiesState.replay;

const getTestState = (): ITestProperties => globalThis.testPropertiesState;

/** Checks if a node is selected and grabs snapshot data */
const loadNodeTraceSnapshotDataForSelectedNode = (
  newTraceIndex: number,
  reactFlowInstance: ReactFlowInstance,
  updateNodeInternals: UpdateNodeInternals,
  functionSubscriber: Partial<FunctionSubscriberContextProps>
) => {
  const testState = getTestState();
  // check for selected
  if (testState.node) {
    const replayState = testState.replay;
    let nodeTrace: INodeTrace | undefined;

    if (newTraceIndex == testState.nodeTraceCount - 1 || newTraceIndex <= replayState.traceIndex) {
      // Moving backward or at the end of the traces. Grab the last nodeTrace for the selected node
      nodeTrace = replayState.nodeTraces.findLast(
        (t) => t != undefined && t.id == testState.node!.id && t.entryIndex <= newTraceIndex
      );

      // Edge case where we skipToLast and start clicking Previous until we approach a gap in the traceEntities
      // If the current trace is on one side of the gap and the previous is more than a batch away
      // Calculate which batch and grab it
      if (nodeTrace && replayState.traceIndex - nodeTrace?.entryIndex > Trace_Entries_Batch_Size) {
        const iteration = Math.floor(replayState.traceIndex / Trace_Entries_Batch_Size);
        const snapIndex = iteration * Trace_Entries_Batch_Size - 1;

        setTraceEntries(replayState.executionId!, snapIndex, functionSubscriber).then(() => {
          // now that entries are filled in, get the previous trace
          nodeTrace = replayState.nodeTraces.findLast(
            (t) => t != undefined && t.id == testState.node!.id && t.entryIndex <= newTraceIndex
          );
          if (nodeTrace && testState.node!.data.nodeProperties.testIoTraceIndex != nodeTrace?.entryIndex) {
            setNodeTraceSnapshotData(
              nodeTrace,
              replayState.nodeTraces,
              reactFlowInstance,
              updateNodeInternals,
              functionSubscriber,
              replayState.executionId!
            );
          }
        });
      } else {
        // main case where we grab data for previous traces
        if (nodeTrace && testState.node.data.nodeProperties.testIoTraceIndex != nodeTrace?.entryIndex) {
          setNodeTraceSnapshotData(
            nodeTrace,
            replayState.nodeTraces,
            reactFlowInstance,
            updateNodeInternals,
            functionSubscriber,
            replayState.executionId!
          );
        }
      }
    } else {
      // Navigating forward (newTraceIndex > replayState.traceIndex). Look for next traceIndex For this node
      nodeTrace = replayState.nodeTraces.find(
        (t) => t != undefined && t.id == testState.node!.id && t.entryIndex >= newTraceIndex
      );

      // if we're on the selected node, potentially grab and display the data
      if (
        nodeTrace &&
        testState.node.data.nodeProperties.testIoTraceIndex != nodeTrace?.entryIndex &&
        nodeTrace.entryIndex == newTraceIndex
      ) {
        setNodeTraceSnapshotData(
          nodeTrace,
          replayState.nodeTraces,
          reactFlowInstance,
          updateNodeInternals,
          functionSubscriber,
          replayState.executionId!
        );
      }
    }
  }
};

/** Orchestrates grabbing snapshot data, saving the values in state and setting node properties on the selected node  **/
const setNodeTraceSnapshotData = (
  nodeTrace: INodeTrace,
  nodeTraces: INodeTrace[],
  reactFlowInstance: ReactFlowInstance,
  updateNodeInternals: UpdateNodeInternals,
  functionSubscriber: Partial<FunctionSubscriberContextProps>,
  executionId: string
): Promise<void> => {
  return new Promise((resolve) => {
    const node = reactFlowInstance.getNode(nodeTrace.id);
    if (node) {
      if (node.selected) {
        const traces = getSelectedTracesWithoutSnapshotData(nodeTraces, nodeTrace, node);
        setTraceSnapshots(executionId, traces, functionSubscriber).then(() => {
          setReplayNodeProperties(node, nodeTrace, reactFlowInstance, updateNodeInternals);
          resolve();
        });
      } else {
        setReplayNodeProperties(node, nodeTrace, reactFlowInstance, updateNodeInternals);
        resolve();
      }
    } else {
      resolve();
    }
  });
};

export const latestTraceMatchesNodeReplayIo = (node: Node<any>): boolean => {
  const testState = getTestState();
  const replayState = testState.replay;
  const currentTraceIndex = replayState.traceIndex;

  let nodeTrace: INodeTrace | undefined;
  nodeTrace = replayState.nodeTraces.findLast(
    (t) => t != undefined && t.id == testState.node!.id && t.entryIndex <= currentTraceIndex
  );
  if (!nodeTrace) {
    const nodeTrace2 = replayState.nodeTraces.find(
      (t) => t != undefined && t.id == testState.node!.id && t.entryIndex >= currentTraceIndex
    );

    if (nodeTrace2 && nodeTrace2?.entryIndex > currentTraceIndex) {
      return true;
    }
  }

  return node!.data.nodeProperties.testIoTraceIndex === nodeTrace?.entryIndex;
};

/** Gets list of traces that need to query for their snapshot data */
const getSelectedTracesWithoutSnapshotData = (
  nodeTraces: INodeTrace[],
  currentTrace: INodeTrace,
  selectedNode: Node<any>
): INodeTrace[] => {
  const nodeTraceWithObjectList = globalThis.nodeTypeDefinitions
    .getDefinitions()
    .filter(
      (nd) =>
        nd?.outputsSchemaProperties ||
        nd?.dataInputs.some((di) => di.type == DataType.OBJECTLIST) ||
        nd?.dataOutputs.some((dos) => dos.type == DataType.OBJECTLIST)
    )
    .map((nd) => nd?.id);
  let batchSize: number = 10;
  if (selectedNode.type && nodeTraceWithObjectList.includes(selectedNode.type)) {
    batchSize = 3;
  }

  // grab all the traces for the current node that haven't had their snapshot set
  const selectedTraces = nodeTraces.filter((t) => t != undefined && t.id == currentTrace.id && !t.jsonSnapshot);
  if (selectedTraces.length == 0) {
    return [];
  }

  const idx = selectedTraces.findIndex((t) => t.entryIndex == currentTrace.entryIndex);

  // if we can't find the current node its already been cached or is in a batch of entries that haven't been downloaded yet
  if (idx == -1) {
    return [];
  }

  const start = Math.max(0, idx - batchSize);
  const end = Math.min(selectedTraces.length, idx + batchSize);

  return selectedTraces.slice(start, end);
};

/** On selection of a node, displays snapshot data **/
export const displaySelectedNodeSnapshotData = (
  selectedNode: Node<any>,
  functionSubscriber: Partial<FunctionSubscriberContextProps>,
  reactFlowInstance: ReactFlowInstance,
  updateNodeInternals: UpdateNodeInternals
) => {
  const replay = getReplayState();
  if (replay && replay.executionId && !replay.isStopped) {
    // Grab trace for entryIndex prior to the current index
    const lastTrace = replay.nodeTraces.findLast(
      (t) => t != undefined && t.id == selectedNode.id && t.entryIndex <= replay.traceIndex
    );
    if (lastTrace) {
      setTraceSnapshots(replay.executionId!, [lastTrace], functionSubscriber).then(() => {
        setReplayNodeProperties(selectedNode, lastTrace, reactFlowInstance, updateNodeInternals);
      });
    }
  }
};

/** Sets the node.data.nodeProperties for input, output and traceIndex from the nodeTrace */
const setReplayNodeProperties = (
  node: Node,
  nodeTrace: INodeTrace,
  reactFlowInstance: ReactFlowInstance,
  updateNodeInternals: UpdateNodeInternals
) => {
  if (nodeTrace.jsonSnapshot) {
    node.data.nodeProperties.testInput = nodeTrace.jsonSnapshot.inputs;
    node.data.nodeProperties.testOutput = nodeTrace.jsonSnapshot.outputs;
    node.data.nodeProperties.testIoTraceIndex = nodeTrace.entryIndex;
    forceNodeUpdate(node, updateNodeInternals, reactFlowInstance);
  }
};

/** Deletes the node.data.nodeProperties related to replay */
const deleteReplayNodeProperties = (node: Node<any>, deleteTestIoTraceIndex: boolean = false) => {
  delete node.data.nodeProperties.testInput;
  delete node.data.nodeProperties.testOutput;
  delete node.data.nodeProperties.isCurrentTrace;
  if (deleteTestIoTraceIndex) {
    delete node.data.nodeProperties.testIoTraceIndex;
  }
};

const timeout = (ms: number) => {
  if (ms === 0) {
    return new Promise<void>((resolve) => resolve());
  }
  return new Promise<void>((resolve) => setTimeout(resolve, ms));
};

const Trace_Entries_Batch_Size: number = 1000;

/** Retrieve the run trace entries from ux action */
export const setTraceEntries = (
  executionId: string,
  traceIndex: number,
  functionSubscriber: Partial<FunctionSubscriberContextProps>
): Promise<void> => {
  let iteration = Math.floor(traceIndex / Trace_Entries_Batch_Size);
  let offset = iteration * Trace_Entries_Batch_Size;
  return new Promise<void>(function (resolve) {
    if (functionSubscriber.plexGetTraceEntries) {
      functionSubscriber.plexGetTraceEntries(executionId, offset, Trace_Entries_Batch_Size).then((response: any) => {
        // splice directly changes the nodeTraces, removing the chunk of entries and replacing with the data from the api
        getReplayState().nodeTraces.splice(offset, response.Data.length, ...response.Data);

        resolve();
      });
    } else {
      console.warn("plexGetTraceEntries does not exist");
      resolve();
    }
  });
};

// Used to track which entryIndexes have requested snapshot data
let queuedEntryIndexes: number[] = [];

/** Set the snapshot data from ux action */
export const setTraceSnapshots = (
  executionId: string,
  traceEntries: INodeTrace[],
  functionSubscriber: Partial<FunctionSubscriberContextProps>
): Promise<void> => {
  const entryIndexes: number[] = [];
  for (const traceEntry of traceEntries) {
    // if we have already set the data, no need to look it up again
    if (traceEntry.jsonSnapshot) {
      continue;
    }

    // return defaults if we have no data to go lookup
    if (!traceEntry.hasSnapshotInput && !traceEntry.hasSnapshotOutput) {
      traceEntry.jsonSnapshot = {
        entryIndex: traceEntry.entryIndex,
        inputs: {},
        outputs: {}
      } as IJsonSnapshot;
      continue;
    }

    // if we've already asked for data and it hasn't resolved, bounce
    if (queuedEntryIndexes.indexOf(traceEntry.entryIndex) !== -1) {
      continue;
    }

    // gather indexes we need to query for
    entryIndexes.push(traceEntry.entryIndex);
    queuedEntryIndexes.push(traceEntry.entryIndex);
  }

  if (entryIndexes.length == 0) {
    return Promise.resolve();
  }

  const promise = new Promise<void>((resolve) => {
    if (functionSubscriber.plexGetTraceSnapshots) {
      functionSubscriber.plexGetTraceSnapshots(executionId, entryIndexes).then((response: any) => {
        var snapshots: IJsonSnapshot[] = response.Data;
        for (const snapshot of snapshots) {
          if (snapshot) {
            snapshot.inputs = snapshot.inputs && snapshot.inputs.trim() !== "" ? JSON.parse(snapshot.inputs) : {};
            snapshot.outputs = snapshot.outputs && snapshot.outputs.trim() !== "" ? JSON.parse(snapshot.outputs) : {};

            const traceEntry = traceEntries.find((t) => t.entryIndex == snapshot.entryIndex);
            if (traceEntry) {
              traceEntry.jsonSnapshot = snapshot;
              // remove resolved entry from queue
              var idx = queuedEntryIndexes.indexOf(traceEntry.entryIndex);
              if (idx !== -1) {
                queuedEntryIndexes.splice(idx, 1);
              }
            }
          }
        }

        resolve();
      });
    } else {
      console.warn("plexGetTraceSnapshots does not exist");
      resolve();
    }
  });

  return promise;
};
