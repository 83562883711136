import React, { FunctionComponent } from "react";
import { NodeProps, useReactFlow } from "reactflow";
import { BasicNodeLayout, NodeTypeCategory } from "../Base";
import { DataType } from "../TypeDefinitions";
import { EdgeDetail } from "../../Util/EdgeUtil";
import { NodeConfigPropertyType } from "../../FlowDocument/PropertyTypeDefinitions";
import { TimeSpanUnit } from "../../FlowDocument/FlowDocumentModel";
import { getOptionLabel } from "../../NodePropertiesForm/EnumFormField";

globalThis.nodeTypeDefinitions.setDefinition({
  componentName: "DateDifferenceNode",
  category: NodeTypeCategory.dates,
  controlOutputs: [],
  dataInputs: [
    { name: "left", type: DataType.DATETIME, hideLabel: true },
    { name: "right", type: DataType.DATETIME, hideLabel: true }
  ],
  dataOutputs: [{ name: "result", type: DataType.INTEGER, hideLabel: true }],
  nodeConfigProperties: [
    {
      name: "unit",
      propertyType: NodeConfigPropertyType.Enum,
      options: { values: TimeSpanUnit }
    },
    {
      name: "inputSchema",
      label: "Input Type",
      propertyType: NodeConfigPropertyType.DataType,
      options: { dataProperties: ["left", "right"], dataTypes: [DataType.DATETIME, DataType.DATE, DataType.TIME] }
    }
  ],
  evaluate: () => {
    return {
      output: {},
      activeControlHandleIds: []
    };
  }
});

export const DateDifferenceNode: FunctionComponent<NodeProps> = ({ id, data }) => {
  const reactFlowInstance = useReactFlow();
  const edgeDetail = new EdgeDetail(id, reactFlowInstance);
  const label = `${edgeDetail.getInputTargetLabel("left")} - ${edgeDetail.getInputTargetLabel("right")}`;
  const unitLabel = getOptionLabel(data.nodeProperties.unit ?? TimeSpanUnit.Second);

  return (
    <BasicNodeLayout id={id} data={data}>
      <div className="plex-fd-expression-label">
        {label}
        <br />
        {unitLabel}
      </div>
    </BasicNodeLayout>
  );
};
