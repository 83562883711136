import { Edge } from "reactflow";
import { IBannerContext } from "@plex/react-components";
import { IDataParam } from "../NodeTypes/Base";

export type SourceInputs = {
  name: string;
  type: string;
  required?: boolean;
  nullable: boolean;
};

export type BaseInputOutput = {
  uniqueId: string;
  name: string;
  type: string;
  schemaId: string;
  isArray: boolean;
  required: boolean;
  enabled: boolean;
  nullable: boolean;
  conditions: undefined | null | Condition[];
  expressions: undefined | null | MathExpression[];
  label?: string;
};

export type Condition = {
  inputValue: string;
  condition: string;
  outputValue: string;
  linker: string;
};

export type MathExpression = {
  leftInputName: string;
  operator: string;
  rightInputName: string;
};

export type SourceInput = BaseInputOutput;

export type SourceOutput = BaseInputOutput;

export type SourceAPI = {
  id: string;
  name: string;
  description: string;
};

export interface IDataSourceAPI extends SourceAPI {
  storedProcedure: string;
  database: string;
  inputCount: number;
  outputCount: number;
}

export interface INodePropertiesFormProps {
  id: string;
  data?: any;
  onEdgeDelete: (edge: Edge) => void;
  onShowSidePane: (showPanel: boolean, type: string, data: any) => void;
  bannerContext: IBannerContext;
}

export interface IDataType {
  [key: string]: string;
}

export const primitiveTypesSelect: IDataType[] = [
  { key: "string", value: "string" },
  { key: "boolean", value: "boolean" },
  { key: "integer", value: "integer" },
  { key: "decimal", value: "decimal" },
  { key: "dateTime", value: "dateTime" },
  { key: "date", value: "date" },
  { key: "time", value: "time" },
  { key: "uuid", value: "uuid" }
];

export const getTypeName = (dataType: DataType) => dataType as string;

export const dataTypes = ["uuid", "string", "integer", "decimal", "boolean", "date", "dateTime", "time"];

export enum DataType {
  OBJECTREFERENCE = "objectReference",
  UUID = "uuid",
  STRING = "string",
  INTEGER = "integer",
  DECIMAL = "decimal",
  BOOLEAN = "boolean",
  DATE = "date",
  DATETIME = "dateTime",
  TIME = "time",
  OBJECT = "object",
  OBJECTLIST = "objectList",
  UUIDLIST = "uuidList",
  STRINGLIST = "stringList",
  INTEGERLIST = "integerList",
  DECIMALLIST = "decimalList",
  BOOLEANLIST = "booleanList",
  DATELIST = "dateList",
  DATETIMELIST = "dateTimeList",
  TIMELIST = "timeList"
}

export const primitiveTypes: DataType[] = [
  DataType.STRING,
  DataType.BOOLEAN,
  DataType.INTEGER,
  DataType.DECIMAL,
  DataType.DATETIME,
  DataType.DATE,
  DataType.TIME,
  DataType.UUID
];

export const isListType = (type: DataType) => {
  const listTypes = [
    DataType.OBJECTLIST,
    DataType.UUIDLIST,
    DataType.STRINGLIST,
    DataType.INTEGERLIST,
    DataType.DECIMALLIST,
    DataType.BOOLEANLIST,
    DataType.DATELIST,
    DataType.DATETIMELIST,
    DataType.TIMELIST
  ];

  return listTypes.some((t: DataType) => t === type);
};

export const isObjectLike = (type: DataType) => {
  return type === DataType.OBJECT || type === DataType.OBJECTLIST;
};

export const shouldConvertToList = (
  assigningType: DataType,
  dataPropertyAssigning: IDataParam,
  hasListOption: boolean
) => {
  const assigningDataPropertyIsList = isListType(dataPropertyAssigning.type);
  return !isListType(assigningType) && assigningDataPropertyIsList && !hasListOption;
};

export const convertToList = (type: DataType) => {
  const listMap: { [from: string]: DataType } = {};
  listMap[DataType.OBJECT] = DataType.OBJECTLIST;
  listMap[DataType.UUID] = DataType.UUIDLIST;
  listMap[DataType.STRING] = DataType.STRINGLIST;
  listMap[DataType.INTEGER] = DataType.INTEGERLIST;
  listMap[DataType.DECIMAL] = DataType.DECIMALLIST;
  listMap[DataType.BOOLEAN] = DataType.BOOLEANLIST;
  listMap[DataType.DATE] = DataType.DATELIST;
  listMap[DataType.DATETIME] = DataType.DATETIMELIST;
  listMap[DataType.TIME] = DataType.TIMELIST;

  const convertedType = listMap[type];

  if (!convertedType) {
    return type;
  }

  return convertedType;
};

export const getDataType = (typeName: string) => {
  const typeNames: { [name: string]: DataType } = {
    uuid: DataType.UUID,
    string: DataType.STRING,
    integer: DataType.INTEGER,
    decimal: DataType.DECIMAL,
    boolean: DataType.BOOLEAN,
    date: DataType.DATE,
    dateTime: DataType.DATETIME,
    time: DataType.TIME
  };
  return typeNames[typeName];
};

export const implicit = (from: DataType, to: DataType): boolean => {
  const implicitConversions: { from: DataType; to: DataType[] }[] = [
    { from: DataType.INTEGER, to: [DataType.DECIMAL] }
  ];

  return implicitConversions.some((c) => c.from === from && c.to.some((t) => t === to));
};

export const implicitSecondary = (from: DataType, to: DataType): boolean => {
  const implicitConversions: { from: DataType; to: DataType[] }[] = [
    { from: DataType.BOOLEAN, to: [DataType.INTEGER] },
    { from: DataType.INTEGER, to: [DataType.BOOLEAN] },
    { from: DataType.DATE, to: [DataType.STRING] },
    { from: DataType.DATETIME, to: [DataType.STRING] },
    { from: DataType.DECIMAL, to: [DataType.INTEGER] },
    { from: DataType.STRING, to: [DataType.DATE, DataType.DATETIME, DataType.TIME, DataType.UUID] },
    { from: DataType.TIME, to: [DataType.STRING] },
    { from: DataType.UUID, to: [DataType.STRING] }
  ];

  return implicitConversions.some((c) => c.from === from && c.to.some((t) => t === to));
};
