import React, { FunctionComponent } from "react";
import { TimeInput, TimePrecision } from "@plex/react-components";
import { DateTimeFormatStyle } from "@plex/culture-core";
import { INodeConfigPropertiesProps } from "../NodeTypes/PropertyTypeDefinitions";

export interface ITimeInputProps extends INodeConfigPropertiesProps {
  label: string;
  formatOptions: DateTimeFormatStyle;
  precision: TimePrecision;
}

export const TimeFormField: FunctionComponent<ITimeInputProps> = (props) => {
  const [value, setValue] = React.useState(null);

  const handleValueChange = (newTemporalValue: any) => {
    props.node!.data.nodeProperties[props.name] = newTemporalValue._time.toString();
    setValue(props.node!.data.nodeProperties[props.name]);
    props.forceNodeUpdate();
  };

  return (
    <TimeInput
      key={`${props.sectionName} ${props.label} Input`}
      value={value}
      onValueChange={handleValueChange}
      formatOptions={props.formatOptions}
      use24HourTime={false}
      precision={props.precision}
      minuteStep={1}
    />
  );
};
